.about {
    width: 100%;
    height: 100vh;
    border-top: 1px solid white;
    display: flex;
}

.about-left {
    width: 50vw;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.about-right {
    width: 50vw; /* Adjust as needed for the space you want */
    position: relative;
}

.about-details-wrapper{
    width: 100%;
    height: 50%;
}

.about-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    background: #fff;
    color: #000;
    flex-direction: row;
    border-left: 1px solid white; /* Move this here to avoid duplicate borders */
}

.about-title {
    font-size: 5vw;
    text-align: center;
    letter-spacing: 5px;
    overflow: hidden;
    line-height: 100%;
    padding: 10px; /* Add padding to visually separate the title from the border */
}

.about-description {
    padding-left: 5%;
    padding-right: 5%;
    font-size: 1.5vw;
    text-align: justify;
    overflow: hidden;
}

.profile-photo {
    width: 80%; /* Adjust this width based on your design */
    height: 80%; /* Adjust this height based on your design */
    background-image: url("photo.jpg");
    background-size: cover;
    background-position: center;
    position: absolute;
    top:0;
    transform: translateX(-150%);
    opacity: 0;
}

.icon {
    transform: translateY(100%);
    overflow: hidden;
    opacity: 0;
}

.social-share {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: left;
    background: #fff;
}

.icon-wrapper{
    padding-right: 5%;
    padding-left: 5%;
}

.header-icons-github { 
    display: inline-block;  
    width: 30px;
    height: 30px;
    background: url('https://ik.imagekit.io/Jagdish/icons8-github-500.png?updatedAt=1701076435661') 50% 50% no-repeat;
    background-size: contain; }

.header-icons-whatsapp { 
    display: inline-block;  
    width: 30px;
    height: 30px;
    background: url('https://ik.imagekit.io/Jagdish/icons8-whatsapp-500.png?updatedAt=1701076371538') 50% 50% no-repeat;
    background-size: contain; }

.header-icons-medium { 
    display: inline-block;  
    width: 30px;
    height: 30px;
    background: url('https://ik.imagekit.io/Jagdish/icons8-medium-500.png?updatedAt=1701076516021') 50% 50% no-repeat;
    background-size: contain; }
.header-icons-linkedin { 
    display: inline-block;  
    width: 30px;
    height: 30px;
    background: url('https://ik.imagekit.io/Jagdish/icons8-linkedin-500.png?updatedAt=1701076489293') 50% 50% no-repeat;
    background-size: contain; }



@media (max-width: 500px) {
    .about {
        flex-direction: row;
        width: 100%;
    }

    .about-left {
        width: 100%;
        height: 50vh;
        order: -1; /* Move this section to the top for mobile */
    }

    .about-right {
        width: 100%;
        height: 100%;
        height: 30vh;
    }

    .about-wrapper {
        height: 80vh;
        border-top: 1px solid white;
        border-left: none; /* Remove the left border for mobile */
    }
    .header-icons-github { 
    display: inline-block;  
    width: 25px;
    height: 25px;
    background: url('https://ik.imagekit.io/Jagdish/icons8-github-500.png?updatedAt=1701076435661') 50% 50% no-repeat;
    background-size: contain; }

.header-icons-whatsapp { 
    display: inline-block;  
    width: 25px;
    height: 25px;
    background: url('https://ik.imagekit.io/Jagdish/icons8-whatsapp-500.png?updatedAt=1701076371538') 50% 50% no-repeat;
    background-size: contain; }

.header-icons-medium { 
    display: inline-block;  
    width: 25px;
    height: 25px;
    background: url('https://ik.imagekit.io/Jagdish/icons8-medium-500.png?updatedAt=1701076516021') 50% 50% no-repeat;
    background-size: contain; }
.header-icons-linkedin { 
    display: inline-block;  
    width: 25px;
    height: 25px;
    background: url('https://ik.imagekit.io/Jagdish/icons8-linkedin-500.png?updatedAt=1701076489293') 50% 50% no-repeat;
    background-size: contain; }
}
