.pd-hero-sec{
    width: 100%;
    height: calc(100% - 55px);
    border-left: 1px solid white;
}

.pd-hero-img-wrapper{
    width: 100%;
    height: calc(100% - 55px);
}

.image-caption{
    color: black;
}
