@import url(../../variables.css);
.navbar {
    width: 100%;
    height: 55px;
    z-index: 100;
    padding: 0;
    margin: 0;
    background: white;
    border: 1px solid rgba(0, 0, 0,0.3);
    overflow: hidden;
    }

    .navbar-name{
      position: relative;
      left: 5%;
      transform: translateY(100%);
      
    }

  .firstname {
    color: black;
    font-size: var(--navtext);
    padding-right: 3px;
  }
  .surname {
    color: palegreen;
    font-size: var(--navtext);
  }

  .nav {
    position: absolute;
    right: 5%;
  }

  .nav-link {
    color: black;
    font-size: calc(0.3rem + 1vw);
    transform: translateY(100%);
    
  }
  .nav-link:hover {
    color: palegreen;
  }
    
    .menu-wrapper:before {
      content : "";
      position: absolute;
      bottom  : 0;
      width   : 100%;  /* or 100px */
      
    }


  .menu{
    text-decoration: none;
    color: black;
    font-size: var(--navtext);
    padding: 0;margin: 0;
    padding-left: 50%;
    height: 100%;
    margin-right:50px;
  }
  .menu:before {
    content : "";
    position: absolute;
    left:0;
    height: 100%;  /* or 100px */
    border-left:1px solid rgba(0, 0, 0, 0.3);
  }