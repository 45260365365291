.projects-section{
    width: 100%;
    height: auto;
    background: white;
    color:black;
}

.projects-wrapper{
    width: 100%;
    height: 100%;
    background: #ffffff;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* grid-template-rows: repeat(2, 1fr);  */
}

.projects-main-title{
    font-size: 5vw;
    text-align: center;
    background-color: white;
    color: black;
}

.projects-title > p{
    margin: 0;padding: 0;
}