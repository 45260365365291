body {
  margin: 0;
  cursor:0
}

body,
html {
  max-width: 100%;height: 100%;
  background-color: white;
  color: black;
  font-family: "bebas neue";
}

