.footer-section{
    width: 100%;
    height: fit-content;   
    position: absolute;
    left: 0px;
    display: flex;
    font-display: center;
}

.my-email-wrapper{
    border-left: 0;
    width: 100%;padding: 0;margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.my-email{
    width: 33.33%;
    height: 100%;
    font-size: 2vw;
    padding: 1%;
    text-align: center;
    font-family: serif;
    color: #808080;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 1px solid grey;
}

.copyright{
    width: 33.33%;height: 100%;
    text-align: center;
    font-size: 1.3vw;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.footer-email{
    color: #133955;
}

.footer-nummber{
    color: #133955;
}

.copyright-symbol{
    color: grey;
    justify-content: center;
    align-items: center;
    text-align: center;
}

