.contact-section{
    width: 100%;
    height: 88vh;
    border-left: 1px solid white;
}


.contact-title{
    font-size: 5vw;
    border-bottom: 1px solid white;
    padding-left: 5%;
}

.form{
    padding-left: 5%;
    padding-top: 3%;
}

.input{
    width: 50%;
}