.certification-image-reveal{
    position: fixed;
    top: -300% !important;;
    left:-30%;
    width: 324px;
    height: 250px;
    overflow: hidden;
    pointer-events: none;
    transform-origin: center;
    opacity: 0;
    transform: scale(0.8);
    transition: all 0.5s ease-out;
    z-index: 2;
}


.certification-image-reveal img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: relative;
    transition: text-transform .5s ease-out;
    background-position: 0 0;
    pointer-events: none;
    background: rgba(0, 0,0, 0);
}



.certification > div > p{
    line-height: 100%;
    
}
.project-horizontal-line{
    width: 100%;
    height: 1px;
    background: #000;
}

.certification{
    width: 100%;
    padding: 2.5em 1em;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.link-wrapper{
    text-decoration: none;
    color: black !important;
}
.certification > div {
    flex: 3;
}

.certification > div:last-child {
    flex: 1;
}