@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Cedarville+Cursive&display=swap');

.hero-section {
    margin: 0;
    margin-top: 55px;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative; /* Ensure relative positioning for absolutely positioned elements */
  }

.horizontal-line{
    width: 100%;
    height: 1px;
    background: #000;
    position: absolute;
    top: 80%;
}

.vertical-line{
    width: 1px;
    height: 100%;
    background: #000;
    position: absolute;
    left: 10%;
}

.circle {
    position: absolute;
    top: -1%;
    left: 70%;
    width: 600px;
    height: 600px;
    border: 1px solid #000;
    border-radius: 50%;
  }
  









.hero-text-wrapper {
    padding-top: 5%;
    display: flex;
    flex-direction: column;
    
    width: 100%;
    height: 100%;
}

.hero-name,
.hero-field {
    text-transform: uppercase;
    font-family: 'Bebas Neue', sans-serif;
    text-align-last: left;
    padding-left: 15%;
}

.hero-name {
    font-size: calc(15vw);
}

.hero-field {
    font-size: calc(8vw);
}

.row {
    width: 100%;
    height: 11vw;
    position: relative;
    overflow: hidden;
    display: flex;
    
}

.hero-row {
    width: 100%;
    position: absolute;
    font-size: 10vw;
    line-height: 10vw;
}

@media (max-width: 480px) {
    .hero-text-wrapper {
        padding-top: 50%;
    }
}
